import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { styles } from '../global/styles';

const StatArbPremDiscScenariosModal = ({type, spreadId, curIndicatorsData, statArbMetadata, minTransactionValue, premDiscRtd, statArbPositions, setOpenStatArbPremDiscScenariosModal, positionId=undefined, singlePositions=undefined, closeDirection=undefined}) => {
  const [lockPremDisc, setLockPremDisc] = useState({});
  const [updatePremDisc, setUpdatePremDisc] = useState(true);
  const [premDiscScenariosSettings, setPremDiscScenariosSettings] = useState({
    direction: closeDirection ? closeDirection : "buy",
    targetZScore: -2,
    targetMargin: 0.005
  })

  const calculateSpreadAtZScore = (indicatorsData, direction, targetZScore) => {
    // Calculate the spread for a given target z-score, mean and standard deviation.
    let spread;
    if (direction === "buy") {
      const spreadMean = indicatorsData?.Indicator?.buy_mean;
      const spreadStd = indicatorsData?.Indicator?.buy_std;
      spread = spreadMean + spreadStd*targetZScore;
    } else if (direction === "sell") {
      const spreadMean = indicatorsData?.Indicator?.sell_mean;
      const spreadStd = indicatorsData?.Indicator?.sell_std;
      spread = spreadMean + spreadStd*targetZScore;
    }

    return spread;
  };

  const calculatePremDiscAtSpread = (spreadId, curPremDiscScenariosSettings, targetSpread, lockPremDisc) => {
    // Calculate prem/disc for a ticker given a target spread and discounts for other tickers in basket.
    // The general formula for two tickers finding the second prem/disc is: x_2 = (y - a*x_1) / b.
    // This can be extended to any number of tickers.
    let numerator = targetSpread;
    let denominator;
    for (let i=0; i<statArbMetadata?.[spreadId]?.basket?.tickers?.length; i++) {
      if (lockPremDisc?.[statArbMetadata?.[spreadId]?.basket?.tickers?.[i]]) {
        numerator -= statArbMetadata?.[spreadId]?.basket?.ratios[i] * (curPremDiscScenariosSettings?.[statArbMetadata?.[spreadId]?.basket?.tickers?.[i]]/100);
      } else {
        denominator = statArbMetadata?.[spreadId]?.basket?.ratios[i];
      }
    }

    return numerator / denominator;
  };

  useEffect(() => {
    if (updatePremDisc) {
      if (!premDiscScenariosSettings?.direction || premDiscScenariosSettings?.direction === "buy") {
        let updatedPremDiscScenariosSettings = {...premDiscScenariosSettings}
        for (let i=0; i<curIndicatorsData?.Indicator?.tickers?.length; i++) {
          if (type === "close") {
            updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]] = parseFloat((singlePositions?.find(e => e?.ticker === curIndicatorsData?.Indicator?.tickers?.[i])?.prem_disc)?.toFixed(2));
            updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]+"price"] = parseFloat(((1+((singlePositions?.find(e => e?.ticker === curIndicatorsData?.Indicator?.tickers?.[i])?.prem_disc/100)))*parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[5]))?.toFixed(2));
          } else {
            if (statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(curIndicatorsData?.Indicator?.tickers?.[i])] > 0) {
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]] = parseFloat((((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[3]))/2)*100)?.toFixed(2));
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]+"price"] = parseFloat(((1+(((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[3]))/2)))*parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[5]))?.toFixed(2));
            } else {
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]] = parseFloat((((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[1]))/2)*100)?.toFixed(2));
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]+"price"] = parseFloat(((1+(((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[1]))/2)))*parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[5]))?.toFixed(2));
            }
          }
        }
        setPremDiscScenariosSettings(updatedPremDiscScenariosSettings);
      } else {
        let updatedPremDiscScenariosSettings = {...premDiscScenariosSettings}
        for (let i=0; i<curIndicatorsData?.Indicator?.tickers?.length; i++) {
          if (type === "close") {
            updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]] = parseFloat((singlePositions?.find(e => e?.ticker === curIndicatorsData?.Indicator?.tickers?.[i])?.prem_disc)?.toFixed(2));
            updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]+"price"] = parseFloat(((1+((singlePositions?.find(e => e?.ticker === curIndicatorsData?.Indicator?.tickers?.[i])?.prem_disc/100)))*parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[5]))?.toFixed(2));
          } else {
            if (statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(curIndicatorsData?.Indicator?.tickers?.[i])] > 0) {
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]] = parseFloat((((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[1]))/2)*100)?.toFixed(2));
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]+"price"] = parseFloat(((1+(((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[1]))/2)))*parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[5]))?.toFixed(2));
            } else {
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]] = parseFloat((((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[3]))/2)*100)?.toFixed(2));
              updatedPremDiscScenariosSettings[curIndicatorsData?.Indicator?.tickers?.[i]+"price"] = parseFloat(((1+(((parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[2])+parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[3]))/2)))*parseFloat(premDiscRtd?.[curIndicatorsData?.Indicator?.tickers?.[i]]?.[5]))?.toFixed(2));
            }
          }
        }
        setPremDiscScenariosSettings(updatedPremDiscScenariosSettings);
      }
      setUpdatePremDisc(false);
    }
  }, [updatePremDisc]);

  const handleClose = () => {
    setOpenStatArbPremDiscScenariosModal(false);
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: "600px",
      height: "600px",
      backgroundColor: "#303030",
      zIndex: 9999999,
      display: "flex",
      flexDirection: "column",
      border: "2px solid black"}}>

      {/* Close Button */}
      <div style={{position: "absolute", top: "5px", right: "5px", cursor: "pointer", color: "white", fontSize: "20px"}} onClick={handleClose}>
        &times;
      </div>

      <h2 style={{textAlign: "center", marginTop: 50, fontSize: 24, marginBottom: 15}}>Prem/Disc Scenarios</h2>
      <hr style={{
        border: '1px solid #000',
        width: '100%',
        marginBottom: 15
      }}/>

      <div style={{flex: 1, padding: 20, float: 'left', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', overflow: 'auto', marginBottom: 60}}>
        {/* Direction */}
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 200, marginBottom: 10 }}>
            <p style={{ fontSize: 12 }}>
              Direction:
            </p>
            <select
              defaultValue={""}
              onChange={(e) => {
                e?.persist();
                setPremDiscScenariosSettings(prevState => ({ 
                  ...prevState, 
                  direction: e?.target?.value, 
                  targetZScore: prevState?.targetZScore*-1 
                }))
              }}
              style={{ width: 100, color: 'black', fontSize: 11 }}
              value={premDiscScenariosSettings?.direction}>
              <option value={"buy"}>Buy</option>
              <option value={"sell"}>Sell</option>
            </select>
          </div>
          {
            type === "open" ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: 200, marginBottom: 10 }}>
                <p style={{ fontSize: 12 }}>
                  Target Z-Score:
                </p>
                <input
                  defaultValue={""}
                  onChange={(e) => {
                    e?.persist();
                    const value = e?.target?.value;
                    if (value === '' || value === '-' || (parseFloat(value) >= -1000000 && parseFloat(value) <= 1000000)) {
                      setPremDiscScenariosSettings(prevState => ({ ...prevState, targetZScore: value }));
                    }
                  }}
                  style={{...styles.input, width: 100, height: 15, fontSize: 11}}
                  value={premDiscScenariosSettings?.targetZScore}/>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: 200, marginBottom: 10 }}>
                <p style={{ fontSize: 12 }}>
                  Target Margin:
                </p>
                <input
                  defaultValue={""}
                  onChange={(e) => {
                    e?.persist();
                    const value = e?.target?.value;
                    if (value === '' || value === '-' || (parseFloat(value) >= -1000000 && parseFloat(value) <= 1000000)) {
                      setPremDiscScenariosSettings(prevState => ({ ...prevState, targetMargin: value }))
                    }
                  }}
                  style={{...styles.input, width: 100, height: 15, fontSize: 11}}
                  value={premDiscScenariosSettings?.targetMargin}/>
              </div>
            )
          }
          <table className="border border-solid border-gray-300/[.45]" style={{ ...styles.table.table, margin: '0 auto', border: '', padding: 0, width: 500 }}>
            <thead style={styles.table.header}>
              <tr style={{fontSize: 10}}>
              <th style={{
                border: 0,
                padding: 10
              }}></th>
              <th style={{
                border: 0,
                padding: 10
              }}>Ticker</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Direction</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Prem/Disc (%)</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Price</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Amount</th>
              <th style={{
                border: 0,
                padding: 10
              }}>Ratio</th>
              </tr>
            </thead>
            <tbody>
            {curIndicatorsData?.Indicator?.tickers.map((ticker, index) => (
              <tr className="hover:bg-gray-700" key={index}>
                {/* Lock prem/disc */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    marginRight: 5,
                    padding: 2
                  }}
                >
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div
                      style={{height: 15, width: 15, backgroundColor: lockPremDisc?.[ticker] ? "#226699" : "white", color: "white", cursor: "pointer"}}
                      onClick={() => {
                        setLockPremDisc(prevState => ({...prevState, [ticker]: !lockPremDisc?.[ticker]}))
                      }}
                    ><span style={{fontSize: 13}}>{lockPremDisc?.[ticker] ? '\u2713' : ""}</span></div> 
                  </div>
                </td>
                {/* Ticker */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    marginRight: 5,
                    padding: 2
                  }}
                >
                  {ticker}
                </td>
                {/* Direction */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "center",
                    backgroundColor:
                      premDiscScenariosSettings?.direction === "buy" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] > 0 ? "green" :
                      premDiscScenariosSettings?.direction === "buy" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] < 0 ? "red" :
                      premDiscScenariosSettings?.direction === "sell" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] > 0 ? "red" :
                      premDiscScenariosSettings?.direction === "sell" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] < 0 ? "green" :
                      "yellow",
                    color: "black",
                    padding: 2
                  }}
                >{
                  premDiscScenariosSettings?.direction === "buy" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] > 0 ? "BUY" :
                  premDiscScenariosSettings?.direction === "buy" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] < 0 ? "SELL" :
                  premDiscScenariosSettings?.direction === "sell" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] > 0 ? "SELL" :
                  premDiscScenariosSettings?.direction === "sell" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] < 0 ? "BUY" :
                  "HOLD"
                }</td>
                {/* Prem/Disc */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    height: 15,
                    padding: 2
                  }}
                >{
                  <input pattern="[0-9,\.]+" value={premDiscScenariosSettings?.[ticker]} style={{...styles.input, width: 75, height: 15,fontSize: 10, display: "inline-block", textAlign: "right", float: "right"}} maxLength="100"
                    onChange={e => {
                      e?.persist();
                      const value = e?.target?.value;
                      if (value === '' || value === '-' || (parseFloat(value) >= -1000000 && parseFloat(value) <= 1000000)) {
                        setPremDiscScenariosSettings(prevState => ({...prevState, [ticker]: value}));
                      }
                    }}
                    onBlur={e => {
                      e?.persist();
                      setPremDiscScenariosSettings(prevState => ({...prevState, [ticker]: parseFloat(premDiscScenariosSettings?.[ticker]) || 0.0}));
                      setPremDiscScenariosSettings(prevState => ({...prevState, [ticker+"price"]: parseFloat(parseFloat((1+parseFloat(premDiscScenariosSettings?.[ticker])/100)*parseFloat(premDiscRtd?.[ticker]?.[5]))?.toFixed(2))}))
                    }}
                  />
                }</td>
                {/* Price */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    padding: 2
                  }}
                >{
                  <input pattern="[0-9,\.]+" value={premDiscScenariosSettings?.[ticker+"price"]} style={{...styles.input, width: 75, height: 15,fontSize: 10, display: "inline-block", textAlign: "right", float: "right"}} maxLength="100"
                    onChange={e => {
                      e?.persist();
                      const value = e?.target?.value;
                      if (value === '' || value === '-' || (parseFloat(value) >= -1000000 && parseFloat(value) <= 1000000)) {
                        setPremDiscScenariosSettings(prevState => ({...prevState, [ticker+"price"]: value}));
                      }
                    }}
                    onBlur={e => {
                      e?.persist();
                      setPremDiscScenariosSettings(prevState => ({...prevState, [ticker+"price"]: parseFloat(premDiscScenariosSettings?.[ticker+"price"]) || 0.0}));
                      setPremDiscScenariosSettings(prevState => ({...prevState, [ticker]: parseFloat((((parseFloat(premDiscScenariosSettings?.[ticker+"price"])/parseFloat(premDiscRtd?.[ticker]?.[5]))-1)*100)?.toFixed(2))}))
                    }}
                  />
                }</td>
                {/* Amount */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    padding: 2
                  }}
                >{
                    // Calculate amount based on price using 25% (for Sell) or 75% (for Buy) of Bid/Ask prem/disc spread.
                    premDiscScenariosSettings?.direction === "buy" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                      Math.abs(Math.round(((statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[spreadId]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+premDiscScenariosSettings?.[ticker]/100)*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() :
                    premDiscScenariosSettings?.direction === "buy" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                      Math.abs(Math.round(((statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[spreadId]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+premDiscScenariosSettings?.[ticker]/100)*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() :
                    premDiscScenariosSettings?.direction === "sell" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] > 0 ?
                      Math.abs(Math.round(((statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[spreadId]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+premDiscScenariosSettings?.[ticker]/100)*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString()
                    : premDiscScenariosSettings?.direction === "sell" && statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)] < 0 ?
                      Math.abs(Math.round(((statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)]/Math.min(...statArbMetadata?.[spreadId]?.basket?.ratios?.map(e => Math.abs(e))))*minTransactionValue)/((1+premDiscScenariosSettings?.[ticker]/100)*parseFloat(premDiscRtd?.[ticker]?.[5]))))?.toLocaleString() : ""
                  }</td>
                {/* Ratio */}
                <td
                  className="border-0 border-b-2 border-solid border-gray-300/[.45]"
                  style={{
                    ...styles.table.algos_table_cell,
                    textAlign: "right",
                    marginRight: 5,
                    padding: 2
                  }}
                >{parseFloat(statArbMetadata?.[spreadId]?.basket?.ratios[statArbMetadata?.[spreadId]?.basket?.tickers?.indexOf(ticker)]).toFixed(2)}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Calculate Scenario Button */}
      <button className='hover-update' style={{position: "absolute", width: 175, bottom: "60px", right: "15px", cursor: "pointer", color: "white", fontSize: "14px"}} onClick={() => {
        setUpdatePremDisc(true);
      }}>
        Reset Prem/Disc
      </button>
      {/* Calculate Scenario Button */}
      <button className='hover-update' style={{position: "absolute", width: 175, bottom: "15px", right: "15px", cursor: "pointer", color: "white", fontSize: "14px"}} onClick={() => {
        // Check if solution exists to equation. Ie. prem/disc for all tickers except one has been locked.
        let numNotLocked = 0;
        let tickerNotLocked;
        for (let i=0; i<statArbMetadata?.[spreadId]?.basket?.tickers?.length; i++) {
          if (!lockPremDisc?.[statArbMetadata?.[spreadId]?.basket?.tickers?.[i]]) {
            numNotLocked += 1;
            tickerNotLocked = statArbMetadata?.[spreadId]?.basket?.tickers?.[i];
          }
        }
        if (numNotLocked !== 1) {
          alert("Solution does not exist. Exactly one ticker has to be not locked.")
        } else {
          let targetSpread;

          if (type === "open") {
            targetSpread = calculateSpreadAtZScore(curIndicatorsData, premDiscScenariosSettings?.direction, parseFloat(premDiscScenariosSettings?.targetZScore));
          } else {
            if (premDiscScenariosSettings?.direction === "buy") {
              targetSpread = statArbPositions?.[positionId]?.open_spread/100 - parseFloat(premDiscScenariosSettings?.targetMargin);
            } else {
              targetSpread = statArbPositions?.[positionId]?.open_spread/100 + parseFloat(premDiscScenariosSettings?.targetMargin);
            }
          }
          const resPremDisc = calculatePremDiscAtSpread(spreadId, premDiscScenariosSettings, targetSpread, lockPremDisc);
          setPremDiscScenariosSettings(prevState => ({...prevState, [tickerNotLocked]: parseFloat(parseFloat(resPremDisc*100)?.toFixed(4))}));
          setPremDiscScenariosSettings(prevState => ({...prevState, [tickerNotLocked+"price"]: parseFloat(parseFloat((1+resPremDisc)*parseFloat(premDiscRtd?.[tickerNotLocked]?.[5]))?.toFixed(2))}));
        }
      }}>
        Calculate Prem/Disc
      </button>
    </div> 
  );
};

export default StatArbPremDiscScenariosModal;
